import { isSSR } from "./isSSR";

export const loadZohoChat = (lang) => {
  if (isSSR()) {
    return;
  }

  if (document.getElementById("zsiqscript")) {
    return;
  }

  window.$zoho = window.$zoho || {};
  window.$zoho.salesiq = window.$zoho.salesiq || {
    widgetcode: process.env.REACT_APP_ZOHO_SALESIQ_CODE,
    values: {},
    ready: function () {
      window.$zoho.salesiq.language(lang);
      window.$zoho.salesiq.chat.waittime(1200);
    }
  };

  const script = document.createElement("script");
  script.type = "text/javascript";
  script.id = "zsiqscript";
  script.defer = true;
  script.src = `https://salesiq.zohopublic.eu/widget?timestamp=${Date.now()}`;

  const firstScriptEl = document.getElementsByTagName("script")[0];
  firstScriptEl.parentNode.insertBefore(script, firstScriptEl);
};

export const removeZohoChat = () => {
  if (window.$zoho && window.$zoho.salesiq) {
    try {
      window.$zoho.salesiq.reset();
    } catch (error) {
      console.error("Помилка reset:", error);
    }

    const scriptElement = document.getElementById("zsiqscript");
    if (scriptElement) {
      scriptElement.parentNode.removeChild(scriptElement);
    }

    const widgetElements = document.querySelectorAll(
      ".zsiq_float, .zsiq_live, .zsiq_chat, .zsiq_widget"
    );

    widgetElements.forEach((element) => {
      if (element && element.parentNode) {
        element.parentNode.removeChild(element);
      }
    });

    for (const key in window.$zoho) {
      if (Object.prototype.hasOwnProperty.call(window.$zoho, key)) {
        delete window.$zoho[key];
      }
    }
  } else {
    console.warn("zoho ще не ініціалізовано.");
  }
};
