import { $api } from "./axios.service";

export class CaptchaService {
  static getCaptchaInfo(userIp) {
    if (userIp) {
      return $api.get("/api/front/captcha", {
        headers: {
          "client-connecting-ip": userIp
        }
      });
    } else {
      return $api.get("/api/front/captcha");
    }
  }
}
