import { createSlice } from "@reduxjs/toolkit";

import { fetchUserActiveOrdersIpAddressCount } from "./user.thunk";

const initialState = {
  user: null,
  userIp: null,
  isAuthenticated: false,
  cabinet: {
    activeOrdersIpAddressCount: {
      loading: false,
      errors: null,
      data: null,
      total: 0
    }
  }
};

const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
      state.isAuthenticated = !!action.payload;

      if (action.payload) {
        localStorage.setItem("user", JSON.stringify(action.payload));
      } else {
        localStorage.removeItem("user");

        state.cabinet.activeOrdersIpAddressCount.total = 0;
        state.cabinet.activeOrdersIpAddressCount.data = null;
        state.cabinet.activeOrdersIpAddressCount.loading = false;
      }
    },
    setUserIp(state, action) {
      state.userIp = action.payload;
    }
  },
  extraReducers: {
    [fetchUserActiveOrdersIpAddressCount.pending]: (state) => {
      state.cabinet.activeOrdersIpAddressCount.loading = true;
    },
    [fetchUserActiveOrdersIpAddressCount.rejected]: (state, action) => {
      state.cabinet.activeOrdersIpAddressCount.errors = action.payload;
      state.cabinet.activeOrdersIpAddressCount.loading = false;
    },
    [fetchUserActiveOrdersIpAddressCount.fulfilled]: (state, action) => {
      let total = 0;
      const data = {};

      for (const key in action.payload) {
        const value = action.payload[key];

        data[key.toUpperCase()] = value;
        total += value;
      }

      state.cabinet.activeOrdersIpAddressCount.total = total;
      state.cabinet.activeOrdersIpAddressCount.data = data;
      state.cabinet.activeOrdersIpAddressCount.loading = false;
    }
  }
});

export const UserActions = {
  ...UserSlice.actions,
  fetchUserActiveOrdersIpAddressCount
};

export default UserSlice.reducer;
