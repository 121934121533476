import { createAsyncThunk } from "@reduxjs/toolkit";

import { CaptchaService } from "../../services";

export const fetchCaptchaInfo = createAsyncThunk(
  "captcha/fetchCaptchaInfo",
  async (userIp, { rejectWithValue }) => {
    try {
      const response = await CaptchaService.getCaptchaInfo(userIp);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data.error);
    }
  }
);
